
@import (reference) '~styles/includes/base';

.ce-backToTopButton {
  position: fixed;
  z-index: 6990; // right below comparison basket
  bottom: @space-3;
  right: @space-3;
  padding: @space-2 @space-3;
  background: @color-light;
  border: 1px solid @color-mono-100;
  color: @color-mono-100;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: @border-radius;
  cursor: pointer;
  transition: all 250ms ease-in-out;

  &.ce-backToTopButton--secondary {
    right: (@space-3 + 50px + @space-3);
    bottom: (@space-3 + ((50px - 36px) / 2)); // 50px = height floating actions (basket toggle), 36px = height backToTopButton
  }

  &:hover {
    background: @color-main;
  }

  svg {
    margin-right: @space-2;
  }
}
