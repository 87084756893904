@import (reference) "base";

.ce-pageHeader {
  position: relative;
  padding-bottom: @space-5;

  &.ce-pageHeader--noPaddingBottom {
    padding-bottom: 0;
  }

  .ce-logo {
    width: 250px;

    img {
      display: block;
      width: 100%;
    }
  }

  a {
    text-decoration: none;
  }

  ul {
    display: flex;
    margin: 0;

    li {
      &:not(:last-child) {
        margin-right: @space-3;

        @media screen and (min-width: @breakpoint-xl) {
          margin-right: @space-4;
        }
      }
    }
  }

  .ce-mainNav {
    margin-right: @space-5;
  }

  .ce-mainNavRight {
    padding: @space-3 0;
  }

  .ce-mobileMenuWrapper {
    display: none;
  }

  .ce-pageHeader-top {
    padding: @space-3 0;
    .ms(0);
    background: url(https://assets-static.colormaterials.sunchemical.com/tiles-header.png) no-repeat top right / contain;

    &, a {
      color: @color-mono-40;
    }

    .ce-subNav {
      padding: @space-2;
      background: fade(@color-mono-100, 80);

      @media screen and (min-width: @breakpoint-sm) {
        margin-right: ~'calc(340px - 5vw)';
      }

      // First breakpoint affected by the 90% restriction of the ce-container class
      @media screen and (min-width: 1422px) {
        margin-right: ~'calc(340px - ((100vw - 1280px) / 2))';
      }

    }
  }

  .ce-pageHeader-main {
    background: @color-light;
    .ms(-1);
    position: relative;
    z-index: 10;

    a, .ce-mobileMenu-toggle {
      color: @color-mono-100;
    }

    @media screen and (min-width: @breakpoint-xl) {
      .ms(0);
    }
  }

  // TODO
  .ce-pageHeader-systemMessage {
    padding: @space-2 @space-3;
    color: @color-mono-100;
    background: @color-secondary;
    border-bottom-left-radius: @border-radius;
    border-bottom-right-radius: @border-radius;
    .ms(-3);

    @media screen and (min-width: @breakpoint-xl) {
      width: @breakpoint-xl;
      max-width: 90%;
      margin: 0 auto;
      .ms(0);
    }

    svg {
      margin-right: 8px;
    }
  }

  @media screen and (max-width: (@breakpoint-xs - 1)) {
    .ce-mobileHiddenLabel {
      display: none;
    }
  }

  @media screen and (min-width: @breakpoint-xl) {
    .ce-mainNavRight {
      ul li:first-child {
        margin-right: 0;
      }
    }
  }

  @media screen and (max-width: (@breakpoint-lg - 1)) {
    .ce-pageHeader-main {
      > .ce-container {
        justify-content: flex-end;
      }
    }

    .ce-mainNav {
      display: none;
    }

    .ce-mobileMenuWrapper {
      display: inline-block;
    }
  }

  @media screen and (max-width: (@breakpoint-sm - 1)) {
    .ce-pageHeader-top {
      background: none;

      > div {
        flex-direction: column;
        align-items: center;

        .ce-subNav {
          margin-top: @space-4;
        }
      }
    }
  }
}
