.ce-backToTopButton {
  position: fixed;
  z-index: 6990;
  bottom: 16px;
  right: 16px;
  padding: 8px 16px;
  background: #ed3024;
  border: 1px solid #fff;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  transition: all 250ms ease-in-out;
}
.ce-backToTopButton.ce-backToTopButton--secondary {
  right: 82px;
  bottom: 23px;
}
.ce-backToTopButton:hover {
  background: #b3251b;
}
.ce-backToTopButton svg {
  margin-right: 8px;
}
