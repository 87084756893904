// Helvetica Neue LT Std 75 Bold
@font-face {
  font-family: "HelveticaNeueLTStd-75Bold";
  src: url("../../fonts/HelveticaNeueLTStd/HelveticaNeueLTStd75bold.eot"); /* IE9 Compat Modes */
  src: url("../../fonts/HelveticaNeueLTStd/HelveticaNeueLTStd75bold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../../fonts/HelveticaNeueLTStd/HelveticaNeueLTStd75bold.otf") format("opentype"), /* Open Type Font */
  url("../../fonts/HelveticaNeueLTStd/HelveticaNeueLTStd75bold.svg") format("svg"), /* Legacy iOS */
  url("../../fonts/HelveticaNeueLTStd/HelveticaNeueLTStd75bold.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../../fonts/HelveticaNeueLTStd/HelveticaNeueLTStd75bold.woff") format("woff"), /* Modern Browsers */
  url("../../fonts/HelveticaNeueLTStd/HelveticaNeueLTStd75bold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

// Helvetica Neue LT Std 85 Heavy
@font-face {
  font-family: "HelveticaNeueLTStd-85Heavy";
  src: url("../../fonts/HelveticaNeueLTStd/HelveticaNeueLTStd85heavy.eot"); /* IE9 Compat Modes */
  src: url("../../fonts/HelveticaNeueLTStd/HelveticaNeueLTStd85heavy.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../../fonts/HelveticaNeueLTStd/HelveticaNeueLTStd85heavy.otf") format("opentype"), /* Open Type Font */
  url("../../fonts/HelveticaNeueLTStd/HelveticaNeueLTStd85heavy.svg") format("svg"), /* Legacy iOS */
  url("../../fonts/HelveticaNeueLTStd/HelveticaNeueLTStd85heavy.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../../fonts/HelveticaNeueLTStd/HelveticaNeueLTStd85heavy.woff") format("woff"), /* Modern Browsers */
  url("../../fonts/HelveticaNeueLTStd/HelveticaNeueLTStd85heavy.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

// Helvetica Neue LT Std 55 Roman
@font-face {
  font-family: "HelveticaNeueLTStd-55Roman";
  src: url("../../fonts/HelveticaNeueLTStd/HelveticaNeueLTStd55roman.eot"); /* IE9 Compat Modes */
  src: url("../../fonts/HelveticaNeueLTStd/HelveticaNeueLTStd55roman.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../../fonts/HelveticaNeueLTStd/HelveticaNeueLTStd55roman.otf") format("opentype"), /* Open Type Font */
  url("../../fonts/HelveticaNeueLTStd/HelveticaNeueLTStd55roman.svg") format("svg"), /* Legacy iOS */
  url("../../fonts/HelveticaNeueLTStd/HelveticaNeueLTStd55roman.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../../fonts/HelveticaNeueLTStd/HelveticaNeueLTStd55roman.woff") format("woff"), /* Modern Browsers */
  url("../../fonts/HelveticaNeueLTStd/HelveticaNeueLTStd55roman.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-helvetica-roman: 'HelveticaNeueLTStd-55Roman';
@font-helvetica-bold: 'HelveticaNeueLTStd-75Bold';
@font-helvetica-heavy: 'HelveticaNeueLTStd-85Heavy';
