
@import (reference) '~styles/includes/base';
.ce-checkbox {
  position: relative;
  cursor: pointer;

  &.disabled {
    opacity: 0.3;
    pointer-events: none;

    .checkbox {
      background: @color-mono-30;
    }
  }

  input {
    visibility: hidden;
    display: none;
  }

  label {
    padding-left: 35px;
    color: inherit;
    text-transform: none;
    position: relative;
    z-index: 9;
    font-weight: 400;
    margin-bottom: 0;
    display: block;
    min-height: 20px;
    line-height: 20px;
    cursor: pointer;

    &.no-label {
      padding-left: 20px;
    }
  }

  &.ce-field {
    label {
      margin-bottom: 0;
      font-weight: 400;
      text-transform: none;
    }
  }

  .checkbox {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    z-index: 5;
    left: 0;
    border: 2px solid @color-mono-30;
    transition: color 250ms ease;
    line-height: 1.25;
    .ms(0);
  }

  &.centered {
    .checkbox {
      top: 50%;
      margin-top: -10px;
    }
  }

  &.checked .checkbox svg {
    color: @color-light;
  }

  .ce-checkbox-partialHint {
    width: 100%;
    height: 3px;
    display: block;
    transform: rotate(-45deg);
    transform-origin: bottom left;
    left: 3px;
    bottom: 1px;
    position: absolute;
    background: @color-light;
  }

  &.ce-checkbox--small {
    label {
      padding-left: 25px;
      min-height: 16px;
      line-height: 16px;

      &.no-label {
        padding-left: 16px;
      }
    }

    .checkbox {
      width: 16px;
      height: 16px;
      .ms(-2);
      line-height: 12px;
    }

    &.centered {
      .checkbox {
        margin-top: -8px;
      }
    }
  }
}
