.ce-confirmationModal .ce-modalContent .center ul {
  display: inline-block;
  margin: 0;
  text-align: left;
}
.ce-confirmationModal .ce-confirmationModalActions {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: -4px;
  margin-right: -4px;
}
.ce-confirmationModal .ce-confirmationModalActions > button {
  margin: 4px;
}
