
@import (reference) '~styles/includes/base';

.ce-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: fade(@color-mono-0, 70);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9900;

  .ce-modalInner {
    width: 600px;
    max-width: 90%;
    max-height: 90%;
    overflow-x: hidden;
    overflow-y: auto;
    background: @color-mono-100;
    color: @color-text-base;
    position: relative;

    a.ce-modalCloseCta {
      position: absolute;
      top: @space-2;
      right: @space-2;
      .ms(1);
      cursor: pointer;
      color: @color-light !important;

      &:hover {
        color: @color-main !important;
      }
    }

    .ce-modalContent {
      padding: @space-4;

      @media screen and (min-width: @breakpoint-xs) {
        padding: @space-4 @space-5;
      }
    }

    .ce-modalHeader {
      padding: 0 @space-3 @space-4 0;
      border-bottom: 1px solid @color-mono-90;

      @media screen and (min-width: @breakpoint-xxs) {
        display: flex;
      }

      .ce-modalHeader-content {
        padding: 0 @space-4;

        p:first-child {
          margin-top: @space-2;
        }

        p:last-child {
          margin-bottom: 0;
        }

        @media screen and (min-width: @breakpoint-xxs) {
          padding: @space-4;
        }
      }

      .ce-modalHeader-logoWrapper {
        max-width: 300px;
        width: 100%;
        .flex-none;
        padding: @space-4;

        @media screen and (min-width: @breakpoint-xxs) {
          width: 50%;
        }
      }

      img {
        max-width: 100%;
      }

      > div:last-child {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
      }

      h2 {
        font-weight: 500;
        color: @color-light;
        margin: 0;
        .ms(1);

        @media screen and (min-width: @breakpoint-sm) {
          .ms(3);
        }
      }
    }
  }
}
