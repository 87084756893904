@import "../../node_modules/normalize.css/normalize.css";
@import "../../node_modules/vue2-animate/src/less/vue2-animate.less";
@import '../../node_modules/bassless/src/basscss';
@import '../../node_modules/bassless/addons/index';
@import "../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";

@import "fonts";
@import "vars";
@import "modularscale";
@import "button";
@import "form";
@import "header";
@import "footer";
@import "chevron";

* {
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
}

html {
  color: @color-mono-0;
}

body {
  font-family: Arial, sans-serif;
  font-weight: 400;
  font-size: @msBase;
  color: @color-text-base;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.overflow-hidden {
    position: fixed;
  }
}

h1, .h1 {
  .ms(6);
  font-family: @font-helvetica-bold;

  @media screen and (min-width: @breakpoint-sm) {
    .ms(8);
  }

  @media screen and (min-width: @breakpoint-lg) {
    .ms(10);
  }
}

h2, .h2 {
  .ms(3);
  font-family: @font-helvetica-bold;

  @media screen and (min-width: @breakpoint-sm) {
    .ms(4);
  }

  @media screen and (min-width: @breakpoint-lg) {
    .ms(7);
  }
}

h3, .h3 {
  font-family: @font-helvetica-bold;
  .ms(1);

  @media screen and (min-width: @breakpoint-sm) {
    .ms(3);
  }
}

a {
  cursor: pointer;
  text-decoration: none;
  color: @color-light;
  transition: all 250ms ease-in-out;

  &:hover {
    color: @color-main;
  }

  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
}

.font-bold {
  font-weight: bold;
}

.ce-pageWrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.ce-pageWrapper--hideMenus {
  padding-top: @space-5;

  @media screen and (max-width: @breakpoint-md) {
    padding-top: 150px;
  }
}

/**
 * Hacks for IE 10+
 */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

  // Taken from: https://github.com/philipwalton/flexbugs
  .flex {
    box-sizing: border-box !important;

    > *:not(.flex):not(.xs-flex):not(.sm-flex):not(.md-flex):not(.lg-flex) {
      // display: block;
    }
  }

  .ce-pageWrapper {
    display: block;
  }
}

/**
 * Font and color utilities
 */

.text-light {
  color: @color-mono-100;
}

.text-right {
  text-align: right;
}

.ce-colorSuccess {
  color: @color-success;
}

.ce-colorError {
  color: @color-error;
}

.ce-colorWarn {
  color: @color-warn;
}

.ce-colorTextBase {
  color: @color-text-base;
}

.ce-colorTextMuted {
  color: @color-mono-60;
}

/**
 * Utility
 */
[v-cloak] {
  display: none;
}

.noselect() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pre-wrap {
  white-space: pre-wrap;
}

.cursor-pointer {
  cursor: pointer;
}

@media print {
  .no-print, .no-print * {
    display: none !important;
  }
}

/**
 * Components
 */
.ce-container {
  width: @breakpoint-xl;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.ce-container--narrow {
  width: @breakpoint-sm;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}


.ce-selectedBadge() {
  position: relative;
  border: 3px solid @color-light;

  &:after {
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid @color-light;
    border-radius: 50%;
    background: @color-mono-100;
    position: absolute;
    bottom: -2px;
    left: 50%;
    transform: translate(-50%, 50%);
  }
}

.ce-backCta {
  position: relative;
  top: -(@space-3 / 2);

  a {
    color: @color-text-base;
    .ms(-1);
    text-decoration: none;
    transition: color 250ms ease-in-out;

    &:hover {
      color: @color-light;
    }
  }
}

.ce-tableOverflowWrapper {
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
}

.ce-fieldWithUnit {
  display: inline;

  > span {
    vertical-align: middle;
  }

  .ce-fieldUnit {
    white-space: nowrap;
    margin-left: @space-1;
  }
}
