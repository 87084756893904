
@import (reference) '~styles/includes/base';

.ce-modal.ce-cookieSettings {
  .ce-cookieDescription {
    line-height: 1.5;
    .ms(-1);
  }

  .ce-cookieDetails {
    .ce-checkbox {
      &.disabled {
        opacity: 1;

        .checkbox {
          opacity: 0.5;
        }
      }

      .checkbox {
        top: 0;
        margin-top: 5px;
      }

      label {
        font-weight: 400;
        .ms(-1);

        strong {
          font-weight: 600;
        }
      }
    }

    .ce-cookieSettings-furtherInfo {
      margin-top: @space-1;
      padding-left: 35px;
      .ms(-1);

      table {
        border-spacing: 0;
        width: 100%;
        margin: @space-3 0;
        border-collapse: collapse;

        th {
          border-top: 1px solid @color-mono-90;
          border-bottom: 2px solid @color-mono-90;
          padding: @space-2;
        }

        td {
          border-bottom: 1px solid @color-mono-92;
          padding: @space-2;
        }

        th {
          text-align: left;
        }
      }
    }
  }
}
