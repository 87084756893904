
@import (reference) "~styles/includes/base";

.ce-menuItem.ce-menuItem--mobile {
  border-bottom: 0 !important;

  .ce-menuItemContent {
    display: flex;
    justify-content: space-between;
    padding: @space-3 @space-4;
  }

  .ce-menuItem-subNavToggle {
    color: @color-mono-100;

    svg {
      transition: transform 250ms ease-in-out;
    }
  }

  .ce-secondLevelNav {
    margin-left: @space-4;
    .ms(1);
    background: @color-sc-light-green;
    padding: @space-2;
    text-shadow: none;
  }

  &.ce-menuItem--subNavOpen {
    .ce-menuItem-subNavToggle {
      svg {
        transform: rotate(-180deg);
      }
    }
  }

  &.ce-menuItem--root {
    &.current, &.section {
      > .ce-menuItemContent {
        font-weight: 700;
        .ms(4);

        a {
          color: @color-mono-100;
        }
      }
    }
  }

  &.ce-menuItem--secondary {
    &.current, &.section {
      > .ce-menuItemContent {
        a {
          color: @color-mono-0;
        }
      }
    }
  }
}
