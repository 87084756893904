@import (reference) "base";

.ce-btn {
  border: none;
  border-radius: @border-radius;
  padding: 11px @space-3;
  cursor: pointer;
  transition: all 250ms ease-in-out;
  outline: none;
  display: inline-block;
  text-decoration: none;
  white-space: nowrap;
  background: none;
  .noselect();
  color: @color-text-base;
  border: 0;

  &.ce-btn--fullWidth {
    width: 100%;
  }

  &.ce-btn-primary {
    .button-colored(@color-main, @color-mono-100);
  }

  &.ce-btn-light {
    .button-colored(@color-light, @color-mono-100);
  }

  &.ce-btn-grey {
    .button-colored(@color-mono-90, @color-text-base);
  }

  &.ce-btn-danger {
    .button-colored(@color-error, @color-mono-100);
  }

  &.ce-btn-warn {
    .button-colored(@color-warn, @color-mono-100);
  }

  &.ce-btn-success {
    .button-colored(@color-success, @color-mono-100);
  }

  &.ce-btn-hover-light {
    &:hover {
      background: @color-light;
      color: @color-mono-100;
    }
  }

  &.ce-btn-grey-to-light {
     background: @color-mono-80;
     color: @color-text-base;

     &:hover {
       background: @color-light;
       color: @color-mono-100;
     }
  }

  &.ce-btn-grey-to-danger {
    background: @color-mono-80;
    color: @color-text-base;

    &:hover {
      background: @color-error;
      color: @color-mono-100;
    }
  }

  &.ce-btn--small {
    padding: @space-2;
    .ms(-1);
  }

  &.ce-btn--tiny {
    padding: @space-1 @space-2;
    .ms(-2);
  }

  &.ce-btn--disabled-look {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.button-colored(@bg, @color) {
  background: @bg;
  color: @color;

  &:hover {
    background: lighten(@bg, 10);
  }
}

.ce-btn--reset {
  cursor: pointer;
  outline: none;
  display: inline-block;
  text-decoration: none;
  background: none;
  color: @color-text-base;
  border: 0;
  transition: all 250ms ease-in-out;
  .noselect();
  padding: 0;

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.ce-btn-as-link {
  .ce-btn--reset;
  color: @color-light;
}
