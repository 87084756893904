@import "../includes/base";

main.ce-defaultPage {
  > .ce-container, .ce-container--narrow {
    background: fade(@color-mono-100, 80);
    padding: @space-3 @space-3 @space-5 @space-3;
    overflow-x: hidden;

    p, ul, ol, h1, h2, h3, h4 {
      &:first-child {
        margin-top: 0;
      }
    }
  }

  table {
    width: 100% !important;
    border-collapse: collapse;

    td {
      padding: @space-2;

      h1, h2, h3, h4 {
        margin: 0;
      }
    }

    tr:nth-child(2n) {
      background: @color-mono-92;
    }

    @media screen and (max-width: (@breakpoint-xs - 1)) {
      tbody {
        display: block;
        width: 90vw;
        overflow: auto;
      }
    }
  }
}
