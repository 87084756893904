.ce-comparisonBasket {
  position: fixed;
  z-index: 7000;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.ce-comparisonBasket .ce-comparisonBasket-contentInner {
  padding: 8px 0 16px;
}
@media screen and (max-width: 1439px) {
  .ce-comparisonBasket .ce-comparisonBasket-contentInner {
    padding-right: 24px;
  }
}
@media screen and (max-width: 1279px) {
  .ce-comparisonBasket .ce-comparisonBasket-contentInner {
    padding: 8px 82px 16px 2.5%;
    max-width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .ce-comparisonBasket .ce-comparisonBasket-contentInner {
    padding: 16px 0;
    max-width: 90%;
    text-align: center;
  }
  .ce-comparisonBasket .ce-comparisonBasket-productsAndActionContainer {
    flex-direction: column;
    align-items: center;
  }
  .ce-comparisonBasket .ce-comparisonBasket-productsAndActionContainer .ce-comparisonBasket-productsWrapper {
    padding-bottom: 16px;
    justify-content: center;
  }
  .ce-comparisonBasket .ce-comparisonBasket-productsAndActionContainer .ce-comparisonBasket-actionsWrapper {
    padding: 0 64px;
  }
}
.ce-comparisonBasket .ce-comparisonBasket-content {
  flex: 1 1 auto;
  min-width: 0;
  /* 1 */
  min-height: 0;
  /* 1 */
  min-height: 82px;
  background: #ebebeb;
  border-top: 2px solid #cccccc;
}
.ce-comparisonBasket .ce-comparisonBasket-content .ce-comparisonBasket-productsWrapper {
  flex: 1 1 auto;
  min-width: 0;
  /* 1 */
  min-height: 0;
  /* 1 */
  display: flex;
  flex-wrap: wrap;
  margin: -4px;
}
.ce-comparisonBasket .ce-comparisonBasket-content .ce-comparisonBasket-productsWrapper > div {
  padding: 8px 32px 8px 8px;
  border-radius: 4px;
  background: #7a7a7a;
  color: #fff;
  margin: 4px;
  font-size: 12.64197531px;
  position: relative;
  overflow: hidden;
  font-weight: 600;
}
.ce-comparisonBasket .ce-comparisonBasket-content .ce-comparisonBasket-productsWrapper > div strong {
  font-weight: 400;
}
.ce-comparisonBasket .ce-comparisonBasket-content .ce-comparisonBasket-productsWrapper > div .ce-comparisonBasketProduct-title {
  padding-right: 8px;
}
.ce-comparisonBasket .ce-comparisonBasket-content .ce-comparisonBasket-productsWrapper > div .ce-comparisonBasketProduct-deleteCta {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 30px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 250ms ease-in-out;
  cursor: pointer;
}
.ce-comparisonBasket .ce-comparisonBasket-content .ce-comparisonBasket-productsWrapper > div .ce-comparisonBasketProduct-deleteCta:hover {
  background: #c50022;
}
.ce-comparisonBasket .ce-comparisonBasket-content .ce-comparisonBasket-productsWrapper > div.ce-comparisonBasket-addMoreHint {
  padding: 8px;
  color: #f39500;
  background: none;
}
.ce-comparisonBasket .ce-comparisonBasket-toggle {
  position: fixed;
  bottom: 16px;
  right: 16px;
}
.ce-comparisonBasket .ce-comparisonBasket-toggle button {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  padding: 0;
}
.ce-comparisonBasket .ce-comparisonBasket-toggle button .fa-layers-counter {
  color: #ed3024;
  background: #fff;
  transform: scale(1);
  font-size: 11.23731139px;
  top: -20px;
  right: -15px;
  font-weight: 700;
  border: 2px solid #ed3024;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  line-height: 20px;
}
.ce-comparisonBasket.ce-comparisonBasket--open .ce-comparisonBasket-toggle button {
  background: #7a7a7a;
}
.ce-comparisonBasket.ce-comparisonBasket--open .ce-comparisonBasket-toggle button:hover {
  background: #ed3024;
}
