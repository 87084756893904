.ce-field.ce-selectField .multiselect .multiselect__tags .multiselect__single {
  background: none;
}
.ce-field.ce-selectField .multiselect .multiselect__tags .multiselect__single:hover {
  cursor: pointer;
}
.ce-field.ce-selectField.ce-selectField--withOtherOption {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}
.ce-field.ce-selectField.ce-selectField--withOtherOption > div {
  width: 100%;
}
@media screen and (min-width: 600px) {
  .ce-field.ce-selectField.ce-selectField--withOtherOption > div:first-child {
    width: 70%;
    padding-right: 8px;
  }
  .ce-field.ce-selectField.ce-selectField--withOtherOption > div:last-child {
    width: 30%;
    padding-left: 8px;
  }
}
.ce-field.ce-selectField.ce-selectField--withOtherOption > div .ce-field {
  margin-bottom: 0;
}
