.ce-pageHeader .ce-userMenuWrapper {
  position: relative;
}
.ce-pageHeader .ce-userMenuWrapper .ce-userMenu {
  position: absolute;
  width: 260px;
  right: 0;
  top: 53px;
  background: #4d4d4d;
  padding: 16px;
  color: #fff;
  border-radius: 4px;
}
.ce-pageHeader .ce-userMenuWrapper .ce-userMenu:before {
  content: "";
  width: 20px;
  height: 20px;
  background: #4d4d4d;
  position: absolute;
  top: -7px;
  right: 6px;
  transform: rotate(45deg);
}
.ce-pageHeader .ce-userMenuWrapper .ce-userMenu ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  list-style: none;
  padding-left: 0;
}
.ce-pageHeader .ce-userMenuWrapper .ce-userMenu ul li {
  padding: 8px;
  margin: 0 !important;
  line-height: 1.5;
  font-size: 14.22222222px;
}
.ce-pageHeader .ce-userMenuWrapper .ce-userMenu ul li a {
  color: #fff;
  display: flex;
  align-items: center;
}
.ce-pageHeader .ce-userMenuWrapper .ce-userMenu ul li a:hover,
.ce-pageHeader .ce-userMenuWrapper .ce-userMenu ul li a.router-link-exact-active {
  color: #ed3024;
}
.ce-pageHeader .ce-userMenuWrapper .ce-userMenu ul li a .icon {
  width: 32px;
  padding-right: 8px;
  display: flex;
  align-items: center;
  flex: none;
}
.ce-pageHeader .ce-userMenuWrapper .ce-userMenu ul li:not(:last-child) {
  margin-bottom: 8px;
  border-bottom: 1px solid #676767;
}
.ce-pageHeader .ce-userMenuWrapper .ce-userMenu ul:not(:first-of-type) {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 2px solid #676767;
}
