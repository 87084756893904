
@import (reference) "~styles/includes/base";

.ce-menuItem.ce-menuItem--desktop {
  &.ce-menuItem--root {
    > a {
      position: relative;
      padding: @space-3 0;
      display: block;

      &:after {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        width: 50%;
        height: 5px;
        background: @color-mono-100;
        opacity: 0;
        transition: all 250ms ease-in-out;
      }
    }

    &.current, &:hover {
      > a:after {
        opacity: 1;
        width: 100%;
      }
    }
  }

  .ce-secondLevelNav {
    position: absolute;
    left: 0;
    width: 100%;
    background: @color-sc-light-green;

    ul {
      flex-direction: column;
    }

    .ce-menuItem.ce-menuItem--secondary {
      padding: @space-3;

      &.current, &:hover {
        a {
          color: @color-mono-0;
        }
      }
    }
  }
}
