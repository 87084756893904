.ce-userRegistration-content > div {
  margin-bottom: 16px;
}
.ce-pageHeader .ce-pageHeader-main .ce-userRegistration-content .ce-userRegistration-login a {
  color: #ed3024;
}
.ce-userRegistration-content .ce-userRegistration-consent-personalData > p:first-child {
  margin-bottom: 4px;
}
.ce-userRegistration-content .ce-userRegistration-consent-personalData > p:last-child {
  margin-top: 4px;
}
.ce-userRegistration-content .ce-userRegistration-consent-personalData > div {
  line-height: 1.5;
}
.ce-userRegistration-content .ce-userRegistration-consent-personalData > div > div {
  display: flex;
  align-items: center;
}
.ce-userRegistration-content .ce-userRegistration-consent-personalData > div > div input {
  margin-right: 4px;
}
.ce-userRegistration-content .ce-userRegistration-industrySelect {
  display: flex;
  align-items: center;
  margin: 8px;
}
.ce-userRegistration-content .ce-userRegistration-industrySelect label {
  margin: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ce-userRegistration-content .ce-userRegistration-industrySelect input {
  margin-right: 4px;
}
.ce-userRegistration-content .ce-userRegistration-consent .ce-field.ce-checkbox {
  margin-bottom: 8px;
}
.ce-userRegistration-content .ce-userRegistration-consent .ce-userRegistration-consent-infoCta {
  font-weight: 400;
  text-decoration: underline;
  color: #464144 !important;
}
.ce-userRegistration-content .ce-userRegistration-consent .tooltip .tooltip-inner {
  max-width: 100%;
  font-size: 12.64197531px;
  font-weight: 400;
}
