
@import (reference) '~styles/includes/base';

.ce-passwordField {
  .ce-passwordField-description {
    margin: @space-3 0;
    .ms(-2);
    .line-height-3;

    &.ce-colorError {
      font-weight: bold;
    }
  }
}
