.chevron {
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    height: 30px;
    width: 30px;
  }

  &::before {
    border-style: solid;
    border-width: 6px 6px 0 0;
    content: '';
    display: inline-block;
    position: relative;
    vertical-align: top;
  }

  &.chevron-right::before {
    transform: rotate(45deg);
  }
  &.chevron-left::before {
    transform: rotate(-135deg);
  }
  &.chevron-up::before {
    transform: rotate(-45deg);
  }
  &.chevron-down::before {
    transform: rotate(135deg);
  }

  &.chevron-left, &.chevron-right {
    width: 40px;
    height: 60px;
  }

  &.chevron-down, &.chevron-up {
    height: 40px;
    width: 60px;
  }

  &.chevron-up::before {
    top: 10px;
  }

  &.chevron-down::before {
    top: -10px;
  }

  &.chevron-right::before {
    left: -10px;
  }

  &.chevron-left::before {
    left: 10px;
  }
}
