.ce-checkbox {
  position: relative;
  cursor: pointer;
}
.ce-checkbox.disabled {
  opacity: 0.3;
  pointer-events: none;
}
.ce-checkbox.disabled .checkbox {
  background: #4d4d4d;
}
.ce-checkbox input {
  visibility: hidden;
  display: none;
}
.ce-checkbox label {
  padding-left: 35px;
  color: inherit;
  text-transform: none;
  position: relative;
  z-index: 9;
  font-weight: 400;
  margin-bottom: 0;
  display: block;
  min-height: 20px;
  line-height: 20px;
  cursor: pointer;
}
.ce-checkbox label.no-label {
  padding-left: 20px;
}
.ce-checkbox.ce-field label {
  margin-bottom: 0;
  font-weight: 400;
  text-transform: none;
}
.ce-checkbox .checkbox {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  z-index: 5;
  left: 0;
  border: 2px solid #4d4d4d;
  transition: color 250ms ease;
  line-height: 1.25;
  font-size: 16px;
}
.ce-checkbox.centered .checkbox {
  top: 50%;
  margin-top: -10px;
}
.ce-checkbox.checked .checkbox svg {
  color: #ed3024;
}
.ce-checkbox .ce-checkbox-partialHint {
  width: 100%;
  height: 3px;
  display: block;
  transform: rotate(-45deg);
  transform-origin: bottom left;
  left: 3px;
  bottom: 1px;
  position: absolute;
  background: #ed3024;
}
.ce-checkbox.ce-checkbox--small label {
  padding-left: 25px;
  min-height: 16px;
  line-height: 16px;
}
.ce-checkbox.ce-checkbox--small label.no-label {
  padding-left: 16px;
}
.ce-checkbox.ce-checkbox--small .checkbox {
  width: 16px;
  height: 16px;
  font-size: 12.64197531px;
  line-height: 12px;
}
.ce-checkbox.ce-checkbox--small.centered .checkbox {
  margin-top: -8px;
}
