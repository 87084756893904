.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-style: normal;
  line-height: 1.5;
  text-align: left;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  word-wrap: break-word;
  font-size: 14.22222222px;
  font-weight: 700;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.tooltip .tooltip-inner {
  max-width: 300px;
  padding: 8px;
  color: #fff;
  background-color: #ed3024;
  border-radius: 4px;
}
.tooltip[x-placement^="top"] {
  padding: 0.4rem 0;
}
.tooltip[x-placement^="top"] .tooltip-arrow {
  bottom: 0;
}
.tooltip[x-placement^="top"] .tooltip-arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #ed3024;
}
.tooltip[x-placement^="right"] {
  padding: 0 0.4rem;
}
.tooltip[x-placement^="right"] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.tooltip[x-placement^="right"] .tooltip-arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #ed3024;
}
.tooltip[x-placement^="bottom"] {
  padding: 0.4rem 0;
}
.tooltip[x-placement^="bottom"] .tooltip-arrow {
  top: 0;
}
.tooltip[x-placement^="bottom"] .tooltip-arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #ed3024;
}
.tooltip[x-placement^="left"] {
  padding: 0 0.4rem;
}
.tooltip[x-placement^="left"] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.tooltip[x-placement^="left"] .tooltip-arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #ed3024;
}
