
@import (reference) '~styles/includes/base';

.ce-forgotPassword-content {
  > div {
    .mb3;
  }

  .ce-forgotPassword-subActions {
    .ce-pageHeader .ce-pageHeader-wrapper & {
      a {
        color: @color-light;
      }
    }
  }
}
