.ce-changedProductRow .ce-changedProductRow-header {
  transition: all 250ms ease-in-out;
  padding: 16px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  display: flex;
}
.ce-changedProductRow .ce-changedProductRow-header .ce-changedProductRow-toggle {
  margin-right: 8px;
}
.ce-changedProductRow .ce-changedProductRow-header .ce-changedProductRow-toggle svg {
  transition: all 250ms ease-in-out;
}
.ce-changedProductRow.ce-changedProductRow--open .ce-changedProductRow-toggle svg {
  transform: rotate(180deg);
}
.ce-changedProductRow .ce-changedProductRow-assets {
  padding-left: 32px;
  font-size: 14.22222222px;
}
.ce-changedProductRow .ce-changedProductRow-assets > div {
  padding: 16px 16px 16px 0;
}
.ce-changedProductRow .ce-changedProductRow-assets > div:not(:last-child) {
  border-bottom: 1px solid #cccccc;
}
div .ce-changedProductRow:not(:last-child) .ce-changedProductRow-header {
  border-bottom: 1px solid #cccccc;
}
div .ce-changedProductRow:nth-child(2n + 1) {
  background: #ebebeb;
}
