@import (reference) "base";

.ce-field {
  &:not([class*="mb0"]):not([class*="m0"]) {
    margin-bottom: @space-3;
  }

  label {
    display: block;
    font-weight: bold;
    text-transform: uppercase;
    .ms(-1);
    .mb1;

    span.ce-colorError {
      text-transform: none;
    }
  }

  &.ce-field--noLabel {
    padding-top: (@space-1 + 16);
  }

  .ce-field-description {
    .mb1;
    margin-top: calc(-1 * @space-1);
    .ms(-2);
  }

  input[type="text"], input[type="email"], input[type="number"], input[type="password"], input[type="search"], select, textarea {
    width: 100%;
    border: 1px solid @color-mono-70;
    height: 40px;
    padding: @space-2;
    line-height: 1;
    background: @color-mono-100;
    outline: none !important;
    transition: all 250ms ease-in-out;
    border-radius: @border-radius;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;

    &:not([readonly]):focus {
      border-color: @color-light;
    }

    &[readonly] {
      color: @color-mono-50;
    }

    &[disabled] {
      opacity: 0.5;
    }
  }

  textarea {
    resize: none;
    min-height: 150px;
    line-height: 1.5;
  }

  select {
    height: 40px;
    line-height: 1.5;
  }

  input[type="checkbox"] {
    + label {
      text-transform: none;
    }
  }

  &.ce-field--checkbox {
    display: flex;
    align-items: center;

    label {
      margin: 0 0 0 @space-2;
      text-transform: none;
    }
  }

  .ce-field-inputWrapper {
    position: relative;

    .ce-field-passwordToggle {
      position: absolute;
      top: 0;
      right: 0;
      line-height: 40px;
      padding: 0 @space-2;
      cursor: pointer;
      .noselect();
    }
  }

  .ce-inputButtonGroup {
    .ce-btn {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .multiselect {
    .multiselect__tags {
      border: 1px solid @color-mono-70;
      border-radius: @border-radius;
      background: @color-mono-100;
      display: flex;
      flex-wrap: wrap;

      input {
        background: none;
        border: 0;
        height: auto;
        padding: 0;
        transition: none;
      }

      .multiselect__tag {
        background: @color-light;

        &.multiselect__tag--disabled {
          pointer-events: none;
        }

        .multiselect__tag-icon {
          &:focus, &:hover {
            background: @color-main;
          }
        }
      }
    }

    .multiselect__content-wrapper {
      border-radius: @border-radius;
      margin-top: @space-2;
      background: @color-mono-100;
      box-shadow: 1px 1px 4px fade(@color-mono-50, 20);
      border: 1px solid @color-mono-70;

      .multiselect__content {
        .multiselect__element {
          .multiselect__option {
            min-height: 0;
            padding: @space-2;

            &.multiselect__option--highlight:not(.multiselect__option--selected) {
              background: @color-light;
              color: @color-mono-100;
            }

            &.multiselect__option--selected {
              background: none;
              color: @color-text-base;
            }

            &:after {
              display: none;
            }
          }
        }
      }
    }

    &.multiselect--disabled {
      .multiselect__select {
        background: none;
      }
    }
  }
}
