.ce-alert {
  padding: 8px 8px;
  border: 1px solid #333333;
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
}
.ce-alert.ce-alert--inline {
  display: inline-flex;
}
.ce-alert.ce-alert--closable .ce-alert-message {
  padding-right: 24px;
}
.ce-alert .ce-alert-closeCta {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px;
  height: 100%;
}
.ce-alert.ce-alert--info {
  color: #464144;
  border-color: #464144;
}
.ce-alert.ce-alert--info a {
  color: #464144 !important;
  text-decoration: underline;
}
.ce-alert.ce-alert--info a:hover {
  color: #2c282a !important;
}
.ce-alert.ce-alert--info .ce-alert-closeCta {
  transition: all 250ms ease-in-out;
}
.ce-alert.ce-alert--info .ce-alert-closeCta:hover {
  background: #464144;
  color: #fff;
}
.ce-alert.ce-alert--success {
  color: #65ac1e;
  border-color: #65ac1e;
}
.ce-alert.ce-alert--success a {
  color: #65ac1e !important;
  text-decoration: underline;
}
.ce-alert.ce-alert--success a:hover {
  color: #4c8116 !important;
}
.ce-alert.ce-alert--success .ce-alert-closeCta {
  transition: all 250ms ease-in-out;
}
.ce-alert.ce-alert--success .ce-alert-closeCta:hover {
  background: #65ac1e;
  color: #fff;
}
.ce-alert.ce-alert--warning {
  color: #f39500;
  border-color: #f39500;
}
.ce-alert.ce-alert--warning a {
  color: #f39500 !important;
  text-decoration: underline;
}
.ce-alert.ce-alert--warning a:hover {
  color: #c07600 !important;
}
.ce-alert.ce-alert--warning .ce-alert-closeCta {
  transition: all 250ms ease-in-out;
}
.ce-alert.ce-alert--warning .ce-alert-closeCta:hover {
  background: #f39500;
  color: #fff;
}
.ce-alert.ce-alert--error {
  color: #c50022;
  border-color: #c50022;
}
.ce-alert.ce-alert--error a {
  color: #c50022 !important;
  text-decoration: underline;
}
.ce-alert.ce-alert--error a:hover {
  color: #920019 !important;
}
.ce-alert.ce-alert--error .ce-alert-closeCta {
  transition: all 250ms ease-in-out;
}
.ce-alert.ce-alert--error .ce-alert-closeCta:hover {
  background: #c50022;
  color: #fff;
}
