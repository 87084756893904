@import '~noty/lib/noty.css';
@import (reference) './base';

.noty_bar {
  .noty_body {
    display: flex;

    .ce-notyIcon {
      width: 20px;
      display: block;
      text-align: center;
      margin-right: @space-2;
    }
  }
}
