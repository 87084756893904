.ce-userLogin-content > div {
  margin-bottom: 16px;
}
.ce-pageHeader .ce-pageHeader-main .ce-userLogin-content .ce-userLogin-subActions a {
  color: #ed3024;
}
@media screen and (min-width: 600px) {
  .ce-userLogin-content .ce-userLogin-subActions {
    display: flex;
    justify-content: space-between;
  }
}
