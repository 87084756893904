.ce-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9900;
}
.ce-modal .ce-modalInner {
  width: 600px;
  max-width: 90%;
  max-height: 90%;
  overflow-x: hidden;
  overflow-y: auto;
  background: #fff;
  color: #464144;
  position: relative;
}
.ce-modal .ce-modalInner a.ce-modalCloseCta {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 18px;
  cursor: pointer;
  color: #ed3024 !important;
}
.ce-modal .ce-modalInner a.ce-modalCloseCta:hover {
  color: #b3251b !important;
}
.ce-modal .ce-modalInner .ce-modalContent {
  padding: 24px;
}
@media screen and (min-width: 600px) {
  .ce-modal .ce-modalInner .ce-modalContent {
    padding: 24px 48px;
  }
}
.ce-modal .ce-modalInner .ce-modalHeader {
  padding: 0 16px 24px 0;
  border-bottom: 1px solid #e6e6e6;
}
@media screen and (min-width: 440px) {
  .ce-modal .ce-modalInner .ce-modalHeader {
    display: flex;
  }
}
.ce-modal .ce-modalInner .ce-modalHeader .ce-modalHeader-content {
  padding: 0 24px;
}
.ce-modal .ce-modalInner .ce-modalHeader .ce-modalHeader-content p:first-child {
  margin-top: 8px;
}
.ce-modal .ce-modalInner .ce-modalHeader .ce-modalHeader-content p:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 440px) {
  .ce-modal .ce-modalInner .ce-modalHeader .ce-modalHeader-content {
    padding: 24px;
  }
}
.ce-modal .ce-modalInner .ce-modalHeader .ce-modalHeader-logoWrapper {
  max-width: 300px;
  width: 100%;
  flex: none;
  padding: 24px;
}
@media screen and (min-width: 440px) {
  .ce-modal .ce-modalInner .ce-modalHeader .ce-modalHeader-logoWrapper {
    width: 50%;
  }
}
.ce-modal .ce-modalInner .ce-modalHeader img {
  max-width: 100%;
}
.ce-modal .ce-modalInner .ce-modalHeader > div:last-child {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.ce-modal .ce-modalInner .ce-modalHeader h2 {
  font-weight: 500;
  color: #ed3024;
  margin: 0;
  font-size: 18px;
}
@media screen and (min-width: 768px) {
  .ce-modal .ce-modalInner .ce-modalHeader h2 {
    font-size: 22.78125px;
  }
}
