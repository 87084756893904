
@import (reference) "~styles/includes/base";

@bgColor: @color-mono-30;
@color: @color-mono-100;

.ce-pageHeader {
  .ce-userMenuWrapper {
    position: relative;

    .ce-userMenu {
      position: absolute;
      width: 260px;
      right: 0;
      top: 53px;
      background: @bgColor;
      padding: @space-3;
      color: @color;
      border-radius: @border-radius;

      &:before {
        content: "";
        width: 20px;
        height: 20px;
        background: @bgColor;
        position: absolute;
        top: -7px;
        right: 6px;
        transform: rotate(45deg);
      }

      ul {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        .list-reset;

        li {
          padding: @space-2;
          margin: 0 !important;
          line-height: 1.5;
          .ms(-1);

          a {
            color: @color;
            display: flex;
            align-items: center;

            &:hover, &.router-link-exact-active {
              color: @color-light;
            }

            .icon {
              width: 32px;
              padding-right: 8px;
              display: flex;
              align-items: center;
              .flex-none;
            }
          }

          &:not(:last-child) {
            margin-bottom: @space-2;
            border-bottom: 1px solid lighten(@bgColor, 10);
          }
        }

        &:not(:first-of-type) {
          margin-top: @space-3;
          padding-top: @space-3;
          border-top: 2px solid lighten(@bgColor, 10);
        }
      }
    }
  }
}
