
@import (reference) "~styles/includes/base";

.ce-mobileMenu {
  position: fixed;
  right: 0;
  top: 0;
  padding-bottom: @space-5;
  background: @color-light;
  .ms(3);
  text-shadow: 2px 2px 2px fade(@color-main, 70);
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  .ms(3);

  @media screen and (min-width: @breakpoint-xxs) {
    max-width: 400px;
  }

  .ce-mobileMenu-logoWrapper {
    width: calc(100% ~'-' @space-4);
    max-width: 250px;
    margin: 80px auto @space-3;
    position: relative;

    img {
      max-width: 100%;
      display: block;
    }
  }

  .ce-mobileMenuCloseCta {
    position: absolute;
    top: @space-3;
    right: @space-3;
    .ms(2);
    cursor: pointer;
    color: @color-mono-100;
  }

  a {
    color: @color-mono-100;
  }

  nav {
    ul {
      display: block;
      margin: 0;
    }
  }
}

body.ce-mobileMenuVisible {
  overflow: hidden;

  header, main, footer, .ce-pageWrapper:after {
    filter: blur(2px) grayscale(1);
  }
}
