.ce-menuItem.ce-menuItem--desktop.ce-menuItem--root > a {
  position: relative;
  padding: 16px 0;
  display: block;
}
.ce-menuItem.ce-menuItem--desktop.ce-menuItem--root > a:after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  width: 50%;
  height: 5px;
  background: #fff;
  opacity: 0;
  transition: all 250ms ease-in-out;
}
.ce-menuItem.ce-menuItem--desktop.ce-menuItem--root.current > a:after,
.ce-menuItem.ce-menuItem--desktop.ce-menuItem--root:hover > a:after {
  opacity: 1;
  width: 100%;
}
.ce-menuItem.ce-menuItem--desktop .ce-secondLevelNav {
  position: absolute;
  left: 0;
  width: 100%;
  background: #99cc44;
}
.ce-menuItem.ce-menuItem--desktop .ce-secondLevelNav ul {
  flex-direction: column;
}
.ce-menuItem.ce-menuItem--desktop .ce-secondLevelNav .ce-menuItem.ce-menuItem--secondary {
  padding: 16px;
}
.ce-menuItem.ce-menuItem--desktop .ce-secondLevelNav .ce-menuItem.ce-menuItem--secondary.current a,
.ce-menuItem.ce-menuItem--desktop .ce-secondLevelNav .ce-menuItem.ce-menuItem--secondary:hover a {
  color: #000;
}
