
@import (reference) '~styles/includes/base';

.ce-userLogin-content {
  > div {
    .mb3;
  }

  .ce-userLogin-subActions {
    .ce-pageHeader .ce-pageHeader-main & {
      a {
        color: @color-light;
      }
    }

    @media screen and (min-width: @breakpoint-xs) {
      display: flex;
      justify-content: space-between;
    }

  }
}
