
@import (reference) '~styles/includes/base';

.ce-field.ce-selectField {
  .multiselect {
    .multiselect__tags {
      .multiselect__single {
        background: none;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  &.ce-selectField--withOtherOption {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;

    > div {
      width: 100%;

      @media screen and (min-width: @breakpoint-xs) {
        &:first-child {
          width: 70%;
          padding-right: @space-2;
        }

        &:last-child {
          width: 30%;
          padding-left: @space-2;
        }
      }

      .ce-field {
        margin-bottom: 0;
      }
    }
  }
}
