
@import (reference) "~styles/includes/base";

.ce-comparisonBasket {
  position: fixed;
  z-index: 7000;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  .ce-comparisonBasket-contentInner {
    padding: @space-2 0 @space-3;

    @media screen and (max-width: (@breakpoint-xxl - 1)) {
      padding-right: @space-4;
    }

    @media screen and (max-width: (@breakpoint-xl - 1)) {
      padding: @space-2 (50px + (2 * @space-3)) @space-3 2.5%;
      max-width: 100%;
    }
  }

  @media screen and (max-width: (@breakpoint-sm - 1)) {
   .ce-comparisonBasket-contentInner {
     padding: @space-3 0;
     max-width: 90%;
     text-align: center;
   }

    .ce-comparisonBasket-productsAndActionContainer {
      flex-direction: column;
      align-items: center;

      .ce-comparisonBasket-productsWrapper {
        padding-bottom: @space-3;
        justify-content: center;
      }

      .ce-comparisonBasket-actionsWrapper {
        padding: 0 @space-6;
      }
    }
  }

  .ce-comparisonBasket-content {
    .flex-auto;
    min-height: 50px + (2 * @space-3);
    background: @color-mono-92;
    border-top: 2px solid @color-mono-80;

    .ce-comparisonBasket-productsWrapper {
      .flex-auto;
      display: flex;
      flex-wrap: wrap;
      margin: -@space-1;

      > div {
        padding: @space-2 @space-4 + @space-2 @space-2 @space-2;
        border-radius: @border-radius;
        background: @color-mono-50;
        color: @color-mono-100;
        margin: @space-1;
        .ms(-2);
        position: relative;
        overflow: hidden;
        font-weight: 600;

        strong {
          font-weight: 400;
        }

        .ce-comparisonBasketProduct-title {
          padding-right: @space-2;
        }

        .ce-comparisonBasketProduct-deleteCta {
          position: absolute;
          right: 0;
          top: 0;
          height: 100%;
          width: 30px;
          padding: @space-2;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: background 250ms ease-in-out;
          cursor: pointer;

          &:hover {
            background: @color-error;
          }
        }

        &.ce-comparisonBasket-addMoreHint {
          padding: @space-2;
          color: @color-warn;
          background: none;
        }
      }
    }
  }

  .ce-comparisonBasket-toggle {
    position: fixed;
    bottom: @space-3;
    right: @space-3;

    button {
      border-radius: 50%;
      width: 50px;
      height: 50px;
      padding: 0;

      .fa-layers-counter {
        color: @color-light;
        background: @color-mono-100;
        transform: scale(1);
        .ms(-3);
        top: -20px;
        right: -15px;
        font-weight: 700;
        border: 2px solid @color-light;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        line-height: 20px;
      }
    }
  }

  &.ce-comparisonBasket--open {
    .ce-comparisonBasket-toggle {
      button {
        background: @color-mono-50;

        &:hover {
          background: @color-light;
        }
      }
    }
  }
}
