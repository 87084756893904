@import (reference) 'base';

footer.ce-pageFooter {
  padding: @space-4 @space-3;
  background: url(https://assets-static.colormaterials.sunchemical.com/tiles-footer.png) no-repeat right bottom, @color-sc-dark-gray;

  @media screen and (min-width: @breakpoint-xs) {
    padding: @space-5;
  }

  a {
    text-decoration: none;
  }

  .ce-pageFooter-main {
    &, a {
      color: @color-mono-100;
    }

    a:hover, a.current {
      text-decoration: underline;
    }
  }

  .ce-footerNav-title {
    font-weight: 700;
    margin-bottom: @space-3;
    .ms(1);
  }

  .ce-pageFooter-bottom {
    &, a {
      color: @color-mono-80;
      .ms(-1);
    }

    a:hover, a.current {
      text-decoration: underline;
    }

    ul {
      .list-reset;
      margin: 0 -@space-2;
      display: flex;
      flex-wrap: wrap;

      li {
        margin: @space-2;
      }
    }

    .ce-pageFooter-bottomRight {
      display: flex;
      flex-direction: column-reverse;
      .ce-pageFooter-social {
        display: flex;
        flex-wrap: wrap;
        .ms(0);
        margin: @space-2 -@space-2 0;

        a {
          color: @color-mono-70;
          transition: color 250ms ease-in-out;
          margin: 0 @space-2;
          display: block;

          &:hover {
            color: @color-mono-90;
          }
        }
      }
    }

    @media screen and (min-width: @breakpoint-md) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-end;

      .ce-pageFooter-bottomRight {
        flex-direction: column;

        .ce-pageFooter-social {
          justify-content: flex-end;
          margin: 0 -@space-2;
        }
      }
    }
  }

  .ce-footerNav {
    ul {
      .list-reset;
      margin: 0;

      li {
        &:not(:last-child) {
          margin-bottom: @space-3;
        }
      }
    }
  }
}
