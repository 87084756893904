
@import (reference) "~styles/includes/base";

.ce-confirmationModal {
  .ce-modalContent {
    .center {
      ul {
        display: inline-block;
        margin: 0;
        text-align: left;
      }
    }
  }

  .ce-confirmationModalActions {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .mxn1;

    > button {
      margin: @space-1;
    }
  }
}
