
@import (reference) '~styles/includes/base';

.ce-userRegistration-content {
  > div {
    .mb3;
  }

  .ce-pageHeader .ce-pageHeader-main & {
    .ce-userRegistration-login {
      a {
        color: @color-light;
      }
    }
  }

  .ce-userRegistration-consent-personalData {
    > p {
      &:first-child {
        margin-bottom: @space-1;
      }
      &:last-child {
        margin-top: @space-1;
      }
    }

    > div {
      line-height: 1.5;

      > div {
        display: flex;
        align-items: center;

        input {
          margin-right: @space-1;
        }
      }
    }
  }

  .ce-userRegistration-industrySelect {
    display: flex;
    align-items: center;
    margin: @space-2;

    label {
      margin: 0;
      .noselect;
    }

    input {
      margin-right: @space-1;
    }
  }

  .ce-userRegistration-consent {
    .ce-field.ce-checkbox {
      margin-bottom: @space-2;
    }

    .ce-userRegistration-consent-infoCta {
      font-weight: 400;
      text-decoration: underline;
      color: @color-text-base !important;
    }

    .tooltip {
      .tooltip-inner {
        max-width: 100%;
        .ms(-2);
        font-weight: 400;
      }
    }
  }
}
