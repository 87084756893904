.ce-modal.ce-cookieSettings .ce-cookieDescription {
  line-height: 1.5;
  font-size: 14.22222222px;
}
.ce-modal.ce-cookieSettings .ce-cookieDetails .ce-checkbox.disabled {
  opacity: 1;
}
.ce-modal.ce-cookieSettings .ce-cookieDetails .ce-checkbox.disabled .checkbox {
  opacity: 0.5;
}
.ce-modal.ce-cookieSettings .ce-cookieDetails .ce-checkbox .checkbox {
  top: 0;
  margin-top: 5px;
}
.ce-modal.ce-cookieSettings .ce-cookieDetails .ce-checkbox label {
  font-weight: 400;
  font-size: 14.22222222px;
}
.ce-modal.ce-cookieSettings .ce-cookieDetails .ce-checkbox label strong {
  font-weight: 600;
}
.ce-modal.ce-cookieSettings .ce-cookieDetails .ce-cookieSettings-furtherInfo {
  margin-top: 4px;
  padding-left: 35px;
  font-size: 14.22222222px;
}
.ce-modal.ce-cookieSettings .ce-cookieDetails .ce-cookieSettings-furtherInfo table {
  border-spacing: 0;
  width: 100%;
  margin: 16px 0;
  border-collapse: collapse;
}
.ce-modal.ce-cookieSettings .ce-cookieDetails .ce-cookieSettings-furtherInfo table th {
  border-top: 1px solid #e6e6e6;
  border-bottom: 2px solid #e6e6e6;
  padding: 8px;
}
.ce-modal.ce-cookieSettings .ce-cookieDetails .ce-cookieSettings-furtherInfo table td {
  border-bottom: 1px solid #ebebeb;
  padding: 8px;
}
.ce-modal.ce-cookieSettings .ce-cookieDetails .ce-cookieSettings-furtherInfo table th {
  text-align: left;
}
