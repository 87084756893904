
@import (reference) "~styles/includes/vars";

.ce-alert-color(@color) {
  color: @color;
  border-color: @color;

  a {
    color: @color !important;
    text-decoration: underline;

    &:hover {
      color: darken(@color, 10) !important;
    }
  }

  .ce-alert-closeCta {
    transition: all 250ms ease-in-out;

    &:hover {
      background: @color;
      color: @color-mono-100;
    }
  }
}

.ce-alert {
  padding: @space-2 @space-2;
  border: 1px solid @color-mono-20;
  border-radius: @border-radius;
  display: flex;
  align-items: flex-start;
  position: relative;
  overflow: hidden;

  &.ce-alert--inline {
    display: inline-flex;
  }

  &.ce-alert--closable {
    .ce-alert-message {
      padding-right: @space-4;
    }
  }

  .ce-alert-closeCta {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: @space-2;
    height: 100%;
  }

  &.ce-alert--info {
    .ce-alert-color(@color-text-base);
  }

  &.ce-alert--success {
    .ce-alert-color(@color-success);
  }

  &.ce-alert--warning {
    .ce-alert-color(@color-warn);
  }

  &.ce-alert--error {
    .ce-alert-color(@color-error);
  }
}
