@import (reference) "vars";

// Mixin for Modular Scale font-size
.ms(@number, @base: @msBase, @ratio: @msRatio) when (isnumber(@number)) {
  @calcRatio: pow(@ratio, abs(@number));
  & when (@number >= 0) {
    @fontSize: (@base * @calcRatio);
    font-size: @fontSize;
  }
  & when (@number < 0) {
    @fontSize: (@base / @calcRatio);
    font-size: @fontSize;
  }
}

// Generator loop
.generate-ms-classes(16);
.generate-ms-classes(@n, @i: -6) when (@i =< @n) {
  .ms@{i} {
    .ms(@i);
  }

  // Recursive call
  .generate-ms-classes(@n, (@i + 1));
}
